import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  LoadScriptNext,
  InfoWindow,
} from "@react-google-maps/api";
import axios from "axios";
import customMarker from "../../images/marker.png";
import { GrZoomOut } from "react-icons/gr";

const PageMapVisitors = ({ selectedServices, filters, onPortSelect }) => {
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [hoveredLocation, setHoveredLocation] = useState(null); // Stato per il marker hoverato
  const [ratings, setRatings] = useState({}); // Stato per memorizzare le medie dei rating
  const [apiLoaded, setApiLoaded] = useState(false); // Stato per controllare il caricamento dell'API
  const [zoom, setZoom] = useState(8);

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const center = {
    lat: 40.10488831382711,
    lng: 9.047293907602606,
  };

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}ports`,
          {
            headers: {
              authToken: localStorage.getItem("AuthToken"),
            },
          }
        );
        setLocations(response.data);
      } catch (error) {
        console.error("Errore nel recupero dei dati delle location:", error);
      }
    };

    fetchLocations();
  }, []);

  //ZOOM DINAMICO MAPPA
  useEffect(() => {
    // Controlla la larghezza dello schermo
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setZoom(7); // Zoom per smartphone
      } else {
        setZoom(8); // Zoom per desktop
      }
    };

    // Inizializza lo zoom al caricamento della pagina
    handleResize();

    // Aggiungi un listener per il resize
    window.addEventListener("resize", handleResize);

    return () => {
      // Rimuovi il listener quando il componente viene smontato
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let filtered = locations;

    if (filters.city) {
      filtered = filtered.filter((location) => location.città === filters.city);
    }
    if (filters.province) {
      filtered = filtered.filter(
        (location) => location.provincia === filters.province
      );
    }
    if (filters.category) {
      filtered = filtered.filter(
        (location) => location.categoria === filters.category
      );
    }

    if (selectedServices.length > 0) {
      filtered = filtered.filter((location) =>
        selectedServices.every((service) => location[service])
      );
    }

    setFilteredLocations(filtered);
  }, [selectedServices, filters, locations]);

  const fetchPortDetails = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}ports/${id}`,
        {
          headers: {
            authToken: localStorage.getItem("AuthToken"),
          },
        }
      );
      onPortSelect(response.data);
    } catch (error) {
      console.error("Errore nel recupero dei dettagli del porto:", error);
    }
  };

  const options = {
    mapTypeControl: false, // Nasconde i bottoni "Mappa" e "Satellite"
    streetViewControl: false, // Mostra il controllo Street View (opzionale)
    fullscreenControl: false, // Mostra il controllo fullscreen (opzionale)
  };

  return (
    <LoadScriptNext
      googleMapsApiKey="AIzaSyBnyvNdZgPENADLjttrMCL-mr5524qgKoo"
      version="weekly"
      onLoad={() => setApiLoaded(true)}
    >
      {apiLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={zoom}
          options={options}
        >
          {filteredLocations.map((location) => (
            <Marker
              key={location.id}
              position={{ lat: location.latitudine, lng: location.longitudine }}
              title={`${location.nomePorto}`}
              icon={{
                url: customMarker,
                scaledSize: new window.google.maps.Size(30, 35),
              }}
              onClick={() => fetchPortDetails(location.id)}
            />
          ))}

          {hoveredLocation && (
            <InfoWindow
              position={{
                lat: hoveredLocation.latitudine,
                lng: hoveredLocation.longitudine,
              }}
              onCloseClick={() => setHoveredLocation(null)}
            >
              <div>
                <strong>{hoveredLocation.nomePorto}</strong>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      ) : (
        <div>Caricamento della mappa...</div>
      )}
    </LoadScriptNext>
  );
};

export default PageMapVisitors;
