import React, { useContext, useState } from "react";
import axios from "axios";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
// import toastStyles from "../../styles/Toast.module.css";
import { toast } from "react-toastify";

function SignUp(props) {
  const [portOwner, setPortOwner] = useState(false);
  const [acceptTerms, setacceptTerms] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  async function onSignUp(e) {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error("Le passwords non coincidono!!!", {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
        fontSize: "10px",
      });
      return;
    }

    try {
      let response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}users`,
        {
          name: e.target[0].value,
          surname: e.target[1].value,
          username: e.target[2].value,
          email: e.target[3].value,
          password: password,
          portOwner: portOwner,
        }
      );
      if (response.data.error) {
        toast.error(response.data.error, {
          position: "top-center",
          autoClose: 5000,
        });
      } else {
        toast.success(response.data.message, {
          position: "top-center",
          autoClose: 5000,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error("Errore durante la registrazione!", {
        position: "top-center",
        autoClose: 2000,
      });
    }
  }

  return (
    <div className="content-wrapper">
      <div className="logo-container">
        <div className="logo"></div>
        <div className="titolo">
          <div className="titolo">Sardinian ports</div>
          <div className="titolo">and landings</div>
        </div>
      </div>

      <div className="form-container">
        <form onSubmit={onSignUp}>
          <input type="text" placeholder="Name" required />
          <input type="text" placeholder="Surname" required />
          <input type="text" placeholder="Username" required />
          <input type="text" placeholder="Email" required />

          <div className="password-wrapper">
            <input
              type={passwordVisible ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <span
              className="eye-icon"
              onClick={() => setPasswordVisible(!passwordVisible)}
            >
              {passwordVisible ? <FaRegEyeSlash /> : <FaRegEye />}
            </span>
          </div>

          <div className="password-wrapper">
            <input
              type={confirmPasswordVisible ? "text" : "password"}
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <span
              className="eye-icon"
              onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
            >
              {confirmPasswordVisible ? <FaRegEyeSlash /> : <FaRegEye />}
            </span>
          </div>

          <div className="radio-button">
            <input
              type="radio"
              name="role"
              value="portOwner"
              checked={portOwner}
              onClick={() => setPortOwner(!portOwner)}
            />
            <p>Port Owner</p>
          </div>

          <div className="radio-button-accept">
            <input
              type="checkbox"
              name="accept"
              required
              value="acceptTerms"
              checked={acceptTerms}
              onClick={() => setacceptTerms(!acceptTerms)}
            />
            <a
              href="https://www.google.it"
              target="_blank"
              rel="noopener noreferrer"
              // onClick={() => props.changeToLogin()}
              className="signup"
            >
              <p>I accept terms of use</p>
            </a>
          </div>

          <button type="submit">SignUp</button>
        </form>
      </div>

      <div className="links-container">
        <a href="#" onClick={() => props.changeToLogin()} className="signup">
          Login
        </a>
      </div>
    </div>
  );
}

export default SignUp;
