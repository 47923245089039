import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../services/AuthContext";
import { AiOutlineLogout } from "react-icons/ai";
import { FaRegEdit, FaArrowLeft } from "react-icons/fa";
import { GrAddCircle } from "react-icons/gr";
import { AiOutlineDelete } from "react-icons/ai";
import "./styles/Home.css";

function Menu({ isOwner, isAdmin, toggleEditing }) {
  const navigate = useNavigate();
  const { setLogin } = useContext(AuthContext);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);

  const onLogOut = () => {
    localStorage.removeItem("AuthToken");
    navigate("/entry");
    setLogin(false);
  };

  const handleToggleEdit = () => {
    setIsEditing(!isEditing);
    toggleEditing(!isEditing); // Notify parent component (Visitors) to update the view
  };

  const handleToggleAdd = () => {
    setIsEditing(!isAdding);
    toggleEditing(!isAdding); // Notify parent component (Visitors) to update the view
  };

  return (
    <div className="Menu">
      <div className="menu-icons">
        {isAdmin && (
          <>
            <button type="button" title="Aggiungi" className="menu-icon-button">
              <GrAddCircle style={{ color: "white", fontSize: "24px" }} />
            </button>
            <button type="button" title="Modifica" className="menu-icon-button">
              <FaRegEdit style={{ color: "white", fontSize: "24px" }} />
            </button>
            <button type="button" title="Elimina" className="menu-icon-button">
              <AiOutlineDelete style={{ color: "white", fontSize: "24px" }} />
            </button>
          </>
        )}
        {isOwner && !isAdmin && (
          <button
            type="button"
            title={isEditing ? "Torna indietro" : "Modifica dati porto"}
            className="menu-icon-button"
            onClick={handleToggleEdit}
            style={{ cursor: "pointer" }}
          >
            {isEditing ? (
              <FaArrowLeft style={{ color: "white", fontSize: "24px" }} />
            ) : (
              <FaRegEdit style={{ color: "white", fontSize: "24px" }} />
            )}
          </button>
        )}
      </div>

      <div className="logo-container-menu">
        <div className="logo-menu"></div>
        <div className="titolo-menu">Ports and landings of Sardinia</div>
        <div className="logo-menu"></div>
      </div>

      <div className="logout">
        <button type="button" onClick={onLogOut} title="Logout">
          <AiOutlineLogout
            style={{
              color: "white",
              fontSize: "24px",
            }}
          />
        </button>
      </div>
    </div>
  );
}

export default Menu;
