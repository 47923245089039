import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

function RequestResetPassword() {
  const [email, setEmail] = useState("");

  async function handleRequest(e) {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}users/request-reset-password`,
        { email }
      );

      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      toast.error(
        error.response?.data?.error || "Errore durante la richiesta.",
        {
          position: "top-center",
          autoClose: 5000,
        }
      );
    }
  }

  return (
    <div className="container">
      <div className="inner-div">
        <div className="content-wrapper">
          <div className="logo-container">
            <div className="logo"></div>
            <div className="titolo">
              <div className="titolo">Sardinian ports</div>
              <div className="titolo">and landings</div>
            </div>
          </div>
          <div className="form-container">
            <form onSubmit={handleRequest}>
              <div
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "18px",
                  textAlign: "center",
                  marginBottom: "3px",
                }}
              >
                Reset Password
              </div>
              <input
                type="email"
                placeholder="Inserisci la tua email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button type="submit">Richiedi Reset</button>
            </form>
          </div>
          <div className="links-container">
            <a
              onClick={() =>
                (window.location.href = "https://porti.digiluc.it")
              }
              className="signup"
            >
              Torna al Login
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RequestResetPassword;
