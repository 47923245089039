import React, { useState } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import "../styles/Entry.css";

function ResetPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [searchParams] = useSearchParams();

  async function handleReset(e) {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error("Le password non coincidono!", {
        position: "top-center",
        autoClose: 3000,
      });
      return;
    }

    const token = searchParams.get("token");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}users/reset-password`,
        { token, newPassword }
      );

      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      const errorMessage =
        error.response?.data?.error ||
        "Errore durante il reset della password.";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
      });
    }
  }

  return (
    <div className="container">
      <div className="inner-div">
        <div className="content-wrapper">
          <div className="logo-container">
            <div className="logo"></div>
            <div className="titolo">
              <div className="titolo">Sardinian ports</div>
              <div className="titolo">and landings</div>
            </div>
          </div>
          <div className="form-container">
            <form onSubmit={handleReset}>
              <div
                style={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "18px",
                  textAlign: "center",
                  marginBottom: "3px",
                }}
              >
                Reset Password
              </div>
              {/* Campo Nuova Password */}
              <div className="password-wrapper">
                <input
                  type={passwordVisible ? "text" : "password"}
                  placeholder="Nuova Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
                <span
                  className="eye-icon"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  {passwordVisible ? <FaRegEyeSlash /> : <FaRegEye />}
                </span>
              </div>

              {/* Campo Conferma Password */}
              <div className="password-wrapper">
                <input
                  type={confirmPasswordVisible ? "text" : "password"}
                  placeholder="Conferma Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                <span
                  className="eye-icon"
                  onClick={() =>
                    setConfirmPasswordVisible(!confirmPasswordVisible)
                  }
                >
                  {confirmPasswordVisible ? <FaRegEyeSlash /> : <FaRegEye />}
                </span>
              </div>

              <button type="submit">Salva</button>
            </form>
          </div>
          <div className="links-container">
            <a
              onClick={() =>
                (window.location.href = "https://porti.digiluc.it")
              }
              className="signup"
            >
              Torna al Login
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
