import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

function ConfirmEmail() {
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const confirmEmail = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");

      if (!token) {
        setMessage("Token mancante.");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}users/confirm-email?token=${token}`
        );

        setMessage(response.data.message);
      } catch (error) {
        console.error("Errore durante la conferma dell'email:", error);
        setMessage(
          error.response?.data?.error || "Errore durante la conferma."
        );
      } finally {
        setLoading(false);
      }
    };

    confirmEmail();
  }, []);

  return (
    <div className="confirm-email-wrapper">
      {loading ? (
        <p>Caricamento...</p>
      ) : (
        <>
          <p>{message}</p>
          <button
            onClick={() => (window.location.href = "https://porti.digiluc.it")}
            style={{
              marginTop: "20px",
              padding: "10px 20px",
              fontSize: "16px",
              backgroundColor: "#007BFF",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Torna alla Homepage
          </button>
        </>
      )}
    </div>
  );
}

export default ConfirmEmail;
